import React, { Fragment } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import Layout from 'components/layout';
import Link from 'components/Link';
import at from 'utils/images/email.svg';
import Hero from 'components/Layout/Hero';
import GetStarted from 'components/GetStarted';
import Bottom from 'components/Bottom/Bottom';
import Community from 'components/community';
import bigminerTechQr from 'utils/images/bigminer-tech-qr.png';

import styles from '../pages.module.css';
import s from './contact.module.css';

import WL, { WL_BIGMINER, WL_HIVEOS } from 'constants/whiteLabel';

class Contact extends React.Component {
  render() {
    return (
      <Layout page="contact" lang={_get(this.props, 'pageContext.lang', 'en')}>
        <Hero headerIntl={<FormattedMessage id="Contact.title" defaultMessage="Contact" />} />
        <div>
          <section className={cx(styles.content, s.content)}>
            <div className={styles.wrapper}>
              <h2 className={s.h2}>
                <FormattedMessage id="Contact.caption1" defaultMessage="Business Inquiries" />
              </h2>

              {
                WL.key === WL_BIGMINER ? (
                  <Fragment>
                    <p>
                      <FormattedMessage id="Contact.caption2-bm" defaultMessage="Technical consulting" />
                    </p>
                    <p>
                      <FormattedMessage id="Contact.bm-qq-1" defaultMessage="Contact QQ: {num}" values={{ num: '2752320097' }} />
                    </p>
                    <div className="">
                      <img src={bigminerTechQr} alt="qr" width="200" />
                    </div>
                  </Fragment>
                ) : null
              }
              {
                WL.key === WL_HIVEOS ? (
                  <Fragment>
                    <p>
                      <FormattedMessage id="Contact.caption2" defaultMessage="For business inquiries and propositions please write to:" />
                    </p>
                    <span className={cx(s.email)}><img alt="email icon" src={at}/><a href={`mailto:${WL.getBusinessEmail()}`}>{WL.getBusinessEmail()}</a></span>
                  </Fragment>
                ) : null
              }
            </div>
          </section>
          <section className={cx(styles.content, s.content)}>
            <div className={styles.wrapper}>
              {
                WL.key === WL_BIGMINER ? (
                  <Fragment>
                    <p>
                      <FormattedMessage id="Contact.caption3-bm" defaultMessage="Official community" />
                    </p>
                    <p>
                      <FormattedMessage id="Contact.bm-qq-2" defaultMessage="Bigminer official QQ exchange group: {num}" values={{ num: '830736277' }} />
                    </p>
                  </Fragment>
                ) : null
              }
              {
                WL.key === WL_HIVEOS ? (
                  <Fragment>
                    <h2 className={s.h2}>
                      <FormattedMessage id="Contact.caption3" defaultMessage="Official Support" />
                    </h2>
                    <p>
                      <FormattedMessage
                        id="Contact.caption4"
                        defaultMessage="We do not consult on choice of GPUs, PSUs, hardware, overclocking settings, flight sheet settings, etc. Please use our {forumLink} or Telegram chats instead. Contact our support if you require assistance with our product, payment system, potential bugs that can be reproduced, etc."
                        values={{ forumLink: <Link external noRef href="https://forum.hiveos.farm" className={styles.link}><FormattedMessage id="Contact.forum" defaultMessage="Forum" /></Link> }}
                      />
                    </p>
                    <span className={cx(s.email)}><img alt="email icon" src={at}/><a href={`mailto:${WL.getSupportEmail()}`}>{WL.getSupportEmail()}</a></span>
                  </Fragment>
                ) : null
              }
            </div>
          </section>

          {
            WL.key === WL_HIVEOS ? (
              <Fragment>
                <section className={cx(styles.content, s.content)}>
                  <div className={styles.wrapper}>
                    <h2 className={s.h2}>
                      <FormattedMessage id="Contact.caption5" defaultMessage="Community Support" />
                    </h2>
                    <p>
                      <FormattedMessage id="Contact.caption6" defaultMessage="You can ask community for any question on Community Telegram Channels, Forum or Discord" />
                    </p>
                    <Community/>

                  </div>
                </section>
              

                <GetStarted/>
              </Fragment>
            ) : null
          }

          <Bottom noCommunity />
        </div>
      </Layout>
    )
  }

}


export default Contact;
